import {Switch, Route} from 'react-router-dom';
import Nav from './component/Nav';
import Footer from './component/Footer';
import Home from './containers/home';
import Policy from './containers/policy';
import Control from './containers/control';
import Organization from './containers/organization';
import Reports from './containers/organization/Reports';
import Faq from './containers/faq';

import './App.scss';


function App() {

    let isPC = () => { // 判断是否为PC端
        if (navigator.userAgent.match(/(iPhone|iPod|Android|ios)/i)) {
            return false;
        } else {
            return true;
        }
    };

    return (
        <div className="App">
            <div className={isPC() ? 'main' : 'main wap'}>
                <Nav/>
                <Switch>
                    <Route exact path={'/'} component={Home}/>
                    <Route path={'/policy'} component={Policy}/>
                    <Route path={'/control/:title?'} component={Control}/>
                    <Route exact path={'/organization'} component={Organization}/>
                    <Route exact path={'/organization/reports'} component={Reports}/>
                    <Route path={'/faq'} component={Faq}/>
                </Switch>
            </div>
            <Footer/>
        </div>
    );
}

export default App;
