import { Fragment } from 'react';
import { inforMationTextList } from './textList';

const Reports = () => {
    return (
        <div className="Organization">
            <div className="con">
                <h1 className="InforMationHeader">
                    百度个人信息保护社会责任报告
                </h1>
                <div className="item">
                    <h2>
                        百度的使命是用科技让复杂的世界更简单。我们希望通过不断地研发新技术、推出新服务，以产品和业务不断提升的智能化推动您的生活更加高效、更加便利。
                        百度的愿景是成为最懂用户，并能帮助人们成长的全球顶级高科技公司。我们希望以多年的技术积累和持续创新的产品，为用户、客户、伙伴提供最贴心的服务；
                        我们将一如既往的重视用户的隐私保护，积极践行个人信息保护社会责任。
                    </h2>
                </div>
                <div className="item">
                    <ul className="ulList">
                        {inforMationTextList.map((i) => (
                            <Fragment key={i.header}>
                                <li className="headerLi">
                                    <h3>{i.header}</h3>
                                </li>
                                <li className="text">
                                    {i.works ? (
                                        i.works.map((i) => (
                                            <Fragment key={i.secondTitle}>
                                                <h3 className="ident">
                                                    {i.secondTitle}
                                                </h3>
                                                {i.textList.map((i) => (
                                                    <p
                                                        className="ident"
                                                        key={i}
                                                    >
                                                        {i}
                                                    </p>
                                                ))}
                                            </Fragment>
                                        ))
                                    ) : (
                                        <>
                                            {i.notIdentText ? (
                                                <p>{i.notIdentText}</p>
                                            ) : null}
                                            {i.textList.map((i) => (
                                                <p className="ident" key={i}>
                                                    {i}
                                                </p>
                                            ))}
                                        </>
                                    )}
                                </li>
                            </Fragment>
                        ))}
                    </ul>
                </div>
                <div className="footer">
                    <h1>百度</h1>
                </div>
            </div>
        </div>
    );
};
export default Reports;
