/**
 * @file 隐私产品管理列表
 * @author wangzhuang
 */

export const controlList = [
    {
        title: '百度APP',
        typeList: [
            {
                title: '隐私政策告知',
                imgSrc: require('../../asset/control/app/1.png').default,
                des: '当您首次打开App时，弹窗向您展示隐私政策链接，通过隐私政策向您充分告知我们的隐私保护规则及措施，'
                    + '并在征得同意后为您提供产品及服务。'
            },
            {
                title: '应用权限说明',
                imgSrc: require('../../asset/control/app/2.png').default,
                des: '您可以通过【我的-设置-关于我们-应用权限说明】了解App申请权限情况，并可直接跳转至系统进行设置。'
            },
            {
                title: '隐私设置',
                imgSrc: require('../../asset/control/app/3.png').default,
                des: '您可以通过【我的-设置-隐私设置】查看并管理您的隐私授权设置。'
            },
            {
                title: '历史设置',
                imgSrc: require('../../asset/control/app/4.png').default,
                des: '您可以通过【我的-设置-历史记录】管理您的搜索及浏览历史记录。'
            },
            {
                title: '个人资料管理',
                imgSrc: require('../../asset/control/app/5.png').default,
                des: '您可以通过【我的-设置-点击用户名/头像-编辑资料】随时管理您所填写的个人资料。'
            },
            {
                title: '个性化推荐',
                imgSrc: require('../../asset/control/app/6.png').default,
                des: '如果您不喜欢我们推荐的内容，可以随时关闭，也可选择“不感兴趣”，还可以向我们举报或反馈。'
            },
            {
                title: '账号注销',
                imgSrc: require('../../asset/control/app/7.png').default,
                des: '您可以通过【我的-设置-账号管理】进行账号注销操作，但注销后您的账号将永久无法使用，且所有百度系产品内'
                    + '数据均无法找回，请您谨慎操作。'
            },
            {
                title: '账号授权解除',
                imgSrc: require('../../asset/control/app/8.png').default,
                des: '您可以通过【我的-设置-账号管理】解除对于第三方产品的账号授权。'
            },
            {
                title: '常见问题帮助',
                imgSrc: require('../../asset/control/app/9.png').default,
                des: '您可以通过【我的-设置-帮助与反馈】 便捷了解隐私保护咨询常见问题，以及进行意见反馈。'
            },
            {
                title: '智能客服',
                imgSrc: require('../../asset/control/app/10.png').default,
                des: '您可以通过【我的-设置-帮助与反馈】咨询我们的智能客服，随时随地获得便捷的在线服务与帮助。'
            },
        ]
    },
    {
        title: '百度地图',
        typeList: [
            {
                title: '隐私政策告知',
                imgSrc: require('../../asset/control/ditu/1.png').default,
                des: '当您首次打开App时，弹窗向您展示隐私政策链接，通过隐私政策向您充分告知我们的隐私保护规则及措施，并在征得同意'
                    + '后为您提供产品及服务。'
            },
            {
                title: '应用权限说明',
                imgSrc: require('../../asset/control/ditu/2.png').default,
                des: '当您首次打开App时，弹窗向您告知App申请权限情况，您可以自主选择是否开启权限，并可在后续使用中随时通过'
                    + '【我的-设置-隐私设置-系统权限设置】直接跳转至系统进行设置。'
            },
            {
                title: '隐私设置',
                imgSrc: require('../../asset/control/ditu/3.png').default,
                des: '您可以通过【我的-设置-隐私设置】查看并管理您的隐私授权设置。'
            },
            {
                title: '语音设置',
                imgSrc: require('../../asset/control/ditu/4.png').default,
                des: '您可以通过【我的-设置-隐私设置】查看并管理您的语音交互设置。'
            },
            {
                title: '个人资料管理',
                imgSrc: require('../../asset/control/ditu/5.png').default,
                des: '您可以通过【我的-个人资料修改】随时管理您所填写的个人资料。'
            },
            {
                title: '搜索历史管理',
                imgSrc: require('../../asset/control/ditu/6.png').default,
                des: '您可以通过点击搜索框【删除】单条记录或者【清空搜索历史】来管理您的搜索历史记录。'
            },
            {
                title: '足迹管理',
                imgSrc: require('../../asset/control/ditu/7.png').default,
                des: '您可以通过【我的-设置-隐私设置-出行记录设置-足迹设置】查看并管理您的足迹设置。'
            },
            {
                title: '出行回顾管理',
                imgSrc: require('../../asset/control/ditu/8.png').default,
                des: '您可以通过【我的-设置-隐私设置-出行记录设置-出行回顾设置】查看并管理您的出行回顾设置。'
            },
            {
                title: '排行榜管理',
                imgSrc: require('../../asset/control/ditu/9.png').default,
                des: '如您参与排行榜，则您的签到、等级等数据会向同样参与排行的用户进行展示，您可以随时通过界面右上角问号图标，选择是否展示。'
            },
            {
                title: '账号注销',
                imgSrc: require('../../asset/control/ditu/10.png').default,
                des: '您可以通过【我的-设置-账号管理】进行账号注销操作，但注销后您的账号将永久无法使用，且所有百度系产品内数据均无法找回，请您谨慎操作。'
            },
            {
                title: '账号授权解除',
                imgSrc: require('../../asset/control/ditu/11.png').default,
                des: '您可以通过【我的-设置-账号管理】解除对于第三方产品的账号授权。'
            },
            {
                title: '帮助与反馈',
                imgSrc: require('../../asset/control/ditu/12.png').default,
                des: '您可以通过【我的-帮助与反馈】使用我们提供的便捷的问题咨询及反馈服务。'
            },

        ]
    },
    {
        title: '百度网盘',
        typeList: [
            {
                title: '隐私政策告知',
                imgSrc: require('../../asset/control/wangpan/1.png').default,
                des: '当您首次打开App时，弹窗向您展示隐私政策链接，通过隐私政策向您充分告知我们的隐私保护规则及措施，并在征得同意后'
                    + '为您提供产品及服务。'
            },
            {
                title: '应用权限说明',
                imgSrc: require('../../asset/control/wangpan/2.png').default,
                des: '您可以通过【我的-设置-关于-应用权限说明】了解App申请权限情况，并可直接跳转至系统进行设置。'
            },
            {
                title: '隐私设置',
                imgSrc: require('../../asset/control/wangpan/3.png').default,
                des: '您可以通过【我的-设置-隐私设置】查看并管理您的隐私授权设置。'
            },
            {
                title: '个人资料管理',
                imgSrc: require('../../asset/control/wangpan/4.png').default,
                des: '您可以通过【我的-设置-个人信息】随时管理您所填写的个人资料。'
            },
            {
                title: '自动备份设置',
                imgSrc: require('../../asset/control/wangpan/5.png').default,
                des: '您可以通过【我的-设置-自动备份设置】进行数据自动备份管理。'
            },
            {
                title: '登录设备管理',
                imgSrc: require('../../asset/control/wangpan/6.png').default,
                des: '您可以通过【我的-设置-设备管理】查看登录设备记录并进行管理。'
            },
            {
                title: '密码锁设置',
                imgSrc: require('../../asset/control/wangpan/7.png').default,
                des: '您可以通过【我的-设置-百度网盘锁屏码/修改锁屏码】设置并管理密码锁，避免他人未经允许打开您的网盘。'
            },
            {
                title: '二级密码设置',
                imgSrc: require('../../asset/control/wangpan/8.png').default,
                des: '您可以通过【我的-设置-二级密码设置】设置二级密码以锁定并保护你网盘中的隐藏空间及您的卡包。'
            },
            {
                title: '隐藏空间',
                imgSrc: require('../../asset/control/wangpan/9.png').default,
                des: '您可以通过【我的-设置-文件夹设置-显示隐藏空间】选择是否在网盘中显示已通过设置二级密码锁定的隐藏空间。'
            },
            {
                title: '账号注销',
                imgSrc: require('../../asset/control/wangpan/10.png').default,
                des: '您可以通过【我的-设置-账号管理】进行账号注销操作，但注销后您的账号将永久无法使用，且所有百度系产品内数据均无法找回，请您谨慎操作。'
            },
            {
                title: '账号授权解除',
                imgSrc: require('../../asset/control/wangpan/11.png').default,
                des: '您可以通过【我的-设置-账号管理】解除对于第三方产品的账号授权。'
            },
            {
                title: '常见问题帮助',
                imgSrc: require('../../asset/control/wangpan/12.png').default,
                des: '您可以通过【我的-帮助与反馈】便捷了解隐私保护咨询常见问题，以及进行意见反馈。'
            },
            {
                title: '智能客服',
                imgSrc: require('../../asset/control/wangpan/13.png').default,
                des: '您可以通过【我的-帮助与反馈】咨询我们的智能客服，随时随地获得便捷的在线服务与帮助。'
            }
        ]
    },
    {
        title: '百度贴吧',
        typeList: [
            {
                title: '隐私政策告知',
                imgSrc: require('../../asset/control/tieba/1.png').default,
                des: '当您首次打开App时，弹窗向您展示隐私政策链接，通过隐私政策向您充分告知我们的隐私保护规则及措施，并在征得同意后为您提供产品及服务。'
            },
            {
                title: '应用权限说明',
                imgSrc: require('../../asset/control/tieba/2.png').default,
                des: '您可以通过【我的-设置-隐私设置-应用权限】了解App申请权限情况，并可直接跳转至系统进行设置。'
            },
            {
                title: '隐私设置',
                imgSrc: require('../../asset/control/tieba/3.png').default,
                des: '您可以通过【我的-设置-隐私设置】查看并管理您的隐私授权设置。'
            },
            {
                title: '兴趣设置',
                imgSrc: require('../../asset/control/tieba/4.png').default,
                des: '您可以通过【我的-设置-兴趣设置】动态调整兴趣偏好，自主控制希望接收的个性化内容。'
            },
            {
                title: '青少年保护',
                imgSrc: require('../../asset/control/tieba/5.png').default,
                des: '您可通过【我的-设置-青少年模式】开启青少年模式并设置独立密码。模式开启后，App会精选适合青少年观看的内容，并限制进入部分吧。'
            },
            {
                title: '仅自己可见',
                imgSrc: require('../../asset/control/tieba/6.png').default,
                des: '您可以在发贴时选择是否“同步到主页”，对贴子可见范围（公开/私密）进行设置。如选择不同步到主页，则为仅自己可见的私密贴。'
            },
            {
                title: '发贴记录管理',
                imgSrc: require('../../asset/control/tieba/7.png').default,
                des: '您可以通过【我的-我的贴子】或【我的-点击头像/用户名-个人主页-贴子】管理您的发贴记录或调整贴子可见范围（公开/私密）。'
            },
            {
                title: '个人资料管理',
                imgSrc: require('../../asset/control/tieba/8.png').default,
                des: '您可以通过【我的-个人资料修改】随时管理您所填写的个人资料。'
            },
            {
                title: '历史记录管理',
                imgSrc: require('../../asset/control/tieba/9.png').default,
                des: '您可以通过【我的-浏览历史】管理您的浏览历史记录。'
            },
            {
                title: '个性化推荐',
                imgSrc: require('../../asset/control/tieba/10.png').default,
                des: '如果您不喜欢我们推荐的个性化内容，可以随时关闭，可选择“不感兴趣”，也可向我们举报或反馈。您还可以通过隐私政策中的个性化推荐开关随时关闭。'
            },
            {
                title: '账号注销',
                imgSrc: require('../../asset/control/tieba/11.png').default,
                des: '您可以通过【我的-设置-账号管理】进行账号注销操作，但注销后您的账号将永久无法使用，且所有百度系产品内数据均无法找回，请您谨慎操作。'
            },
            {
                title: '账号授权解除',
                imgSrc: require('../../asset/control/tieba/12.png').default,
                des: '您可以通过【我的-设置-账号管理】解除对于第三方产品的账号授权。'
            },
            {
                title: '意见反馈',
                imgSrc: require('../../asset/control/tieba/13.png').default,
                des: '您可以通过【我的-设置-意见反馈】使用我们提供的便捷的问题咨询及反馈服务。'
            }
        ]
    },
    {
        title: '小度',
        typeList: [
            {
                title: '隐私政策告知',
                imgSrc: require('../../asset/control/xiaodu/1.png').default,
                des: '当您首次打开App时向您展示隐私政策链接，通过隐私政策向您充分告知我们的隐私保护规则及措施，并在征得同意后为您提供产品及服务。'
            },
            {
                title: '儿童保护',
                imgSrc: require('../../asset/control/xiaodu/2.png').default,
                des: '您可通过【我的设备-家长设置】对您的孩子使用小度在家、小度音箱等App绑定设备的时间段、使用时长等进行设置，'
                    + '并可以选择开启儿童模式。儿童模式开启后，App及绑定设备会精选适合儿童观看/收听的内容。'
            },
            {
                title: '个人资料管理',
                imgSrc: require('../../asset/control/xiaodu/3.png').default,
                des: '您可以通过【我的-设置-个人信息管理】随时管理您所填写的个人资料。'
            },
            {
                title: '个性化推荐',
                imgSrc: require('../../asset/control/xiaodu/4.png').default,
                des: '如果您不喜欢我们推荐的个性化内容，可以通过【我的-关于小度-个性化推荐设置】进行关闭。'
            },
            {
                title: '第三方账号管理',
                imgSrc: require('../../asset/control/xiaodu/5.png').default,
                des: '您可以通过【我的-设置-第三方账号管理】绑定或解绑第三方账号。'
            },
            {
                title: '账号注销',
                imgSrc: require('../../asset/control/xiaodu/6.png').default,
                des: '您可以通过【我的-设置-百度账号管理】进行百度账号注销操作，但注销后您的百度账号将永久无法使用，且所有百度系产品内数据均无法找回，请您谨慎操作。'
            },
            {
                title: '账号授权解除',
                imgSrc: require('../../asset/control/xiaodu/7.png').default,
                des: '您可以通过【我的-设置-百度账号管理】解除对于第三方产品的百度账号授权。'
            },
            {
                title: '常见问题帮助',
                imgSrc: require('../../asset/control/xiaodu/8.png').default,
                des: '您可以通过【我的-关于小度-隐私常见问答】便捷了解隐私保护咨询常见问题。'
            },
            {
                title: '智能客服',
                imgSrc: require('../../asset/control/xiaodu/9.png').default,
                des: '您可以通过【我的-咨询客服】咨询我们的智能客服，随时随地获得便捷的在线服务与帮助。'
            }
        ]
    },
    {
        title: '好看视频',
        typeList: [
            {
                title: '隐私政策告知',
                imgSrc: require('../../asset/control/haokan/1.png').default,
                des: '当您首次打开App时，弹窗向您展示隐私政策链接，通过隐私政策向您充分告知我们的隐私保护规则及措施，并在征得同意后为您提供产品及服务。'
            },
            {
                title: '应用权限说明',
                imgSrc: require('../../asset/control/haokan/2.png').default,
                des: '您可以通过【我的-设置-关于我们-应用权限说明】了解App申请权限情况，并可直接跳转至系统进行设置。'
            },
            {
                title: '青少年保护',
                imgSrc: require('../../asset/control/haokan/3.png').default,
                des: '您可通过【我的-设置-青少年模式】开启青少年模式并设置独立密码。模式开启后，App会精选适合青少年观看的内容，并限制操作范围及使用时长。'
            },
            {
                title: '个人资料管理',
                imgSrc: require('../../asset/control/haokan/4.png').default,
                des: '您可以通过【我的-点击头像/用户名-编辑资料】随时管理您所填写的个人资料。'
            },
            {
                title: '历史记录管理',
                imgSrc: require('../../asset/control/haokan/5.png').default,
                des: '您可以通过【我的-观看历史】管理您的浏览历史记录。'
            },
            {
                title: '个性化推荐',
                imgSrc: require('../../asset/control/haokan/6.png').default,
                des: '如果您不喜欢我们推荐的个性化内容，可通过点击视频右上方箭头按钮选择“不感兴趣”，或向我们举报或反馈，还可以通过【我的-设置-隐私设置】随时关闭。'
            },
            {
                title: '账号注销',
                imgSrc: require('../../asset/control/haokan/7.png').default,
                des: '您可以通过【我的-设置-账号管理】进行账号注销操作，但注销后您的账号将永久无法使用，且所有百度系产品内数据均无法找回，请您谨慎操作。'
            },
            {
                title: '账号授权解除',
                imgSrc: require('../../asset/control/haokan/8.png').default,
                des: '您可以通过【我的-设置-账号管理】解除对于第三方产品的账号授权。'
            },
            {
                title: '帮助与反馈',
                imgSrc: require('../../asset/control/haokan/9.png').default,
                des: '您可以通过【我的-设置-帮助与反馈】使用我们提供的便捷的问题咨询及反馈服务。'
            }
        ]
    },
    {
        title: '百度输入法',
        typeList: [
            {
                title: '隐私政策告知',
                imgSrc: require('../../asset/control/shurufa/1.png').default,
                des: '当您首次打开App时，向您展示隐私政策链接，通过隐私政策向您充分告知我们的隐私保护规则及措施，并在征得同意后为您提供产品及服务。'
            },
            {
                title: '词库管理',
                imgSrc: require('../../asset/control/shurufa/2.png').default,
                des: '您可以通过【我的-输入设置-词库设置】主动选择是否导入通讯录或随时一键清空所导入的内容。'
            },
            {
                title: '云备份管理',
                imgSrc: require('../../asset/control/shurufa/3.png').default,
                des: '您可以通过【我的-输入设置-云备份】主动选择是否将用户词及设备项等进行云端备份，也可以随时清空云端备份。'
            },
            {
                title: '个人资料管理',
                imgSrc: require('../../asset/control/shurufa/4.png').default,
                des: '您可以通过【我的-点击头像/用户名-编辑资料】随时管理您所填写的个人资料。'
            },
            {
                title: '账号注销',
                imgSrc: require('../../asset/control/shurufa/5.png').default,
                des: '您可以通过【我的-设置-云备份-账号管理】进行账号注销操作，但注销后您的账号将永久无法使用，且所有百度系产品内数据均无法找回，请您谨慎操作。'
            },
            {
                title: '账号授权解除',
                imgSrc: require('../../asset/control/shurufa/6.png').default,
                des: '您可以通过【我的-设置-云备份-账号管理】解除对于第三方产品的账号授权。'
            },
            {
                title: '建议与反馈',
                imgSrc: require('../../asset/control/shurufa/7.png').default,
                des: '您可以通过【我的-建议与反馈】使用我们提供的便捷的问题咨询及反馈服务。'
            }
        ]
    },
    {
        title: '百度阅读',
        typeList: [
            {
                title: '隐私政策告知',
                imgSrc: require('../../asset/control/yuedu/1.png').default,
                des: '当您首次打开App时，弹窗向您展示隐私政策链接，通过隐私政策向您充分告知我们的隐私保护规则及措施，并在征得同意后为您提供产品及服务。'
            },
            {
                title: '隐私设置',
                imgSrc: require('../../asset/control/yuedu/2.png').default,
                des: '您可以通过【我的-设置-隐私设置】查看并管理您的隐私授权设置。'
            },
            {
                title: '个人资料管理',
                imgSrc: require('../../asset/control/yuedu/3.png').default,
                des: '您可以通过【我的-设置-点击头像-编辑资料】随时管理您所填写的个人资料。'
            },
            {
                title: '账号问题帮助',
                imgSrc: require('../../asset/control/yuedu/4.png').default,
                des: '你可以通过【我的-设置-账号问题帮助】便捷了解账号保护咨询常见问题。'
            },
            {
                title: '账号注销',
                imgSrc: require('../../asset/control/yuedu/5.png').default,
                des: '您可以通过【我的-设置-账号与安全】进行账号注销操作，但注销后您的账号将永久无法使用，且所有百度系产品内数据均无法找回，请您谨慎操作。'
            },
            {
                title: '账号授权解除',
                imgSrc: require('../../asset/control/yuedu/6.png').default,
                des: '您可以通过【我的-设置-账号与安全】解除对于第三方产品的账号授权。'
            },
            {
                title: '帮助与反馈',
                imgSrc: require('../../asset/control/yuedu/7.png').default,
                des: '您可以通过【我的-设置-帮助与反馈】使用我们提供的便捷的问题咨询及反馈服务。'
            }
        ]
    }
];
