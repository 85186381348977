/**
 * @file 常见问题
 * @author wangzhuang
 */

import React from 'react';
import PageBanner from '../../component/PageBanner';
import {faqList} from './faqList';
import './index.scss';

export default class Faq extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className={'Organization faq'}>
                <PageBanner
                    title={'常见问题'}
                    titleEn={'FAQ'}
                    des={'我们梳理了一些在隐私保护方面用户比较关注的问题，希望能够帮助解决您遇到的问题。'}
                    imgSrc={require('../../asset/faq/banner.png').default}
                />

                <div className={'con'}>
                    {
                        faqList.map((item, index) => {
                            /* bca-disable */
                            return <div className={'item'} key={index}>
                                <h3 className={'titleF'}>
                                    {item.title}
                                </h3>
                                <p dangerouslySetInnerHTML={{__html: item.text}}>
                                </p>
                            </div>;
                        })
                    }
                </div>
            </div>
        );
    }

}
