/**
 * @file 产品列表
 * @author wangzhuang
 */

export const productList = [
    {
        title: '百度APP',
        imgSrc: require('../../asset/home/product/baidu-app.png').default,
        href: 'https://s.bdstatic.com/common/agreement/privacy.html'
    },
    {
        title: '百度地图',
        imgSrc: require('../../asset/home/product/baidu-ditu.png').default,
        href: 'https://map.baidu.com/zt/client/privacy/index.html'
    },
    {
        title: '百度网盘',
        imgSrc: require('../../asset/home/product/baidu-wangpan.png').default,
        href: 'https://pan.baidu.com/wap/privacy_policy'
    },
    {
        title: '百度贴吧',
        imgSrc: require('../../asset/home/product/baidu-tieba.png').default,
        href: 'https://tieba.baidu.com/tb/cms/tieba-fe/tieba_promise.html'
    },
    {
        title: '小度',
        imgSrc: require('../../asset/home/product/xiaodu.png').default,
        href: 'https://xiaodu.baidu.com/saiya/xiaodu/privacylaw/index.html'
    },
    {
        title: '好看视频',
        imgSrc: require('../../asset/home/product/haokanshipin.png').default,
        href: 'https://haokan.baidu.com/videoui/page/about#privacy'
    },
    {
        title: '百度输入法',
        imgSrc: require('../../asset/home/product/baidu-shurufa.png').default,
        href: 'https://srf.baidu.com/privacy/index.html'
    },
    {
        title: '百度阅读',
        imgSrc: require('../../asset/home/product/baidu-yuedu.png').default,
        href: 'https://yd.baidu.com/hybrid/law_privacy'
    }
];
