/**
 * @file 首页
 * @author wangzhuang
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import TitileBox from './TitleBox';
import ImgText from './ImgText';
import {productList} from './productList';
import './index.scss';

const bannerList = [
    {
        imgSrc: require('../../asset/home/banner/shibinge.png').default,
        href: 'https://anquan.baidu.com/m/product/appprivacy'
    },
    {
        imgSrc: require('../../asset/home/banner/dituanquan.jpg').default,
        href: 'http://tech.cnr.cn/techph/20200923/t20200923_525272790.shtml'
    },
    {
        imgSrc: require('../../asset/home/banner/wangpan.jpg').default,
        href: 'https://baijiahao.baidu.com/s?id=1660391709227921253&wfr=spider&for=pc'
    }
];

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bannerPresent: 0
        };
        this.timer = null;

    }

    componentDidMount() {
        this.setInterFn();
    }

    setInterFn = () => {
        if (bannerList.length < 2) {
            return; // banner列表小于两个不执行轮播
        }
        let num = this.state.bannerPresent;

        this.timer = setInterval(() => {
            num++;
            if (num >= bannerList.length) {
                num = 0;
            }
            this.setState({
                bannerPresent: num
            });
        }, 3500);
    };

    render() {
        let {bannerPresent} = this.state;
        return (
            <div className={'home'}>
                <div
                    className={'banner'}
                    onMouseEnter={() => {
                        clearInterval(this.timer);
                    }}
                    onMouseLeave={() => {
                        this.setInterFn();
                    }}
                >
                    <ul>
                        {
                            bannerList.map((item, index) => {
                                return <li className={index === bannerPresent ? 'on' : ''} key={index}>
                                    <a
                                        href={item.href}
                                        target={'_blank'}
                                        style={{backgroundImage: `url(${item.imgSrc})`}}
                                    >
                                    </a>
                                </li>;
                            })
                        }
                    </ul>
                    <div className={'slider'}>
                        {
                            bannerList.map((item, index) => {
                                return <div
                                    className={index === bannerPresent ? 'on' : ''}
                                    key={index}
                                    onClick={() => {
                                        this.setState({
                                            bannerPresent: index
                                        });
                                    }}
                                >
                                </div>;
                            })
                        }
                    </div>
                </div>

                <div className={'section'}>
                    <TitileBox title={'百度数据隐私保护价值观'} text={'Privacy Protection Values'}/>

                    <div className={'mod1'}>
                        <div className={'item'}>
                            <ImgText
                                imgSrc={require('../../asset/home/3C-1.png').default}
                                title={'同意 Consent'}
                                text={'充分尊重您，在您可感知的情况下收集您的信息，并取得您的同意'}
                            />
                        </div>
                        <div className={'item'}>
                            <ImgText
                                imgSrc={require('../../asset/home/3C-2.png').default}
                                title={'透明 Clarity'}
                                text={'以您易读的方式向您阐述我们收集、处理个人信息的原则'}
                            />
                        </div>
                        <div className={'item'}>
                            <ImgText
                                imgSrc={require('../../asset/home/3C-3.png').default}
                                title={'可控 Control'}
                                text={'在产品设计上增加隐私保护功能，给予您对您个人信息充分的控制权'}
                            />
                        </div>
                    </div>
                </div>

                <div className={'section gray'}>
                    <TitileBox title={'我们的产品'} text={'Our Apps'}/>
                    <div className={'mod2'}>
                        {
                            productList.map((item, index) => {
                                return <div className={'item'} key={index}>
                                    <div className={'apps'}>
                                        <div className={'left'}>
                                            <img src={item.imgSrc} alt={item.title}/>
                                        </div>
                                        <div className={'right'}>
                                            <div className={'app-name titleF'}>
                                                {item.title}
                                            </div>
                                            <div className={'app-type'}>
                                                <a href={item.href} target={'_blank'}>隐私政策</a>
                                                <div
                                                    onClick={() => {
                                                        this.props.history.push(`/control/${item.title}`);
                                                    }}
                                                >
                                                    隐私管理
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>;
                            })
                        }
                        <div className={'item'}></div>
                    </div>
                </div>

                <div className={'section'}>
                    <TitileBox title={'权威认证'} text={'Certificates & Awards'}/>

                    <div className={'mod3'}>
                        ISO 27018 公有云个人信息保护管理体系<br/>
                        ISO 29151 个人数据隐私保护管理体系<br/>
                        云服务用户数据保护能力(公有云)<br/>
                        云服务用户数据保护能力(私有云)<br/>
                        PCI-DSS 支付卡行业数据安全标准(金融云)<br/>
                        ISO 27701 隐私信息管理体系<br/>
                        BS 10012 个人信息安全安管理体系<br/>
                        百度地图获得首批移动互联网应用程序(App)安全认证<br/>
                        史宾格隐私合规检测安全平台获得“2020 年网络安全国家标准优秀实践案例”二等奖
                    </div>
                </div>

                <div className={'mod4'}>
                    <div>
                        <div className={'title titleF'}>隐私保护咨询</div>
                        <div className={'text'}>如果您在使⽤百度产品或服务过程中，有任何隐私保护问题，可点击按钮与我们联系。</div>
                        <a className={'btn'} href={'http://help.baidu.com/personalinformation'} target={'_blank'}>
                            反馈问题
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Home);
