/**
 * @file 底部bar
 * @author wangzhuang
 */

import React from 'react';
import './index.scss';

export default class Footer extends React.Component {
    render() {
        return (
            <div className={'Footer'}>
                <a href={'https://home.baidu.com/'} target={'_blank'}>关于我们</a>
                <a href={'https://www.baidu.com/duty/'} target={'_blank'}>使用百度前必读</a>
                <a href={'https://beian.miit.gov.cn/'} target={'_blank'}>京公网安备11000002000001号</a>
                <a href={'https://beian.miit.gov.cn/'} target={'_blank'}>京ICP证030173号</a>
                <div>©{new Date().getFullYear()} Baidu</div>
            </div>
        );
    }
}
