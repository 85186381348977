/**
 * @file 常见问题数据列表
 * @author wangzhuang
 */

export const faqList = [
    {
        title: '百度会收集哪些用户信息？收集用户个人信息目的是什么？',
        text: '百度会根据隐私政策披露的情况收集、使用用户信息，各产品/服务收集、使用用户个人信息目的可能因业务功能不同有所差异，'
            + '建议使用前通过本隐私保护平台以及各产品/服务界面查阅具体产品/服务的隐私政策内容。',
    },
    {
        title: '如何注销百度账号？注销账号后用户信息去哪里了？',
        text: '在百度 App 登录账号，点击“设置”—“账号管理”—“账号注销”，系统会根据账号情况显示能否注销。如满足注销条件按照提示点击'
            + '下一步，验证账号绑定手机或人工刷脸验证后即可注销。<br/><br/>'
            + '百度将在不超过15个工作日内完成核查和处理，用户注销账号后，百度将根据法律法规要求删除或者匿名化处理用户的个人信息。',
    },
    {
        title: '用户不喜欢百度推荐的个性化新闻、广告等内容，如何调整关闭？',
        text: '用户可点击推荐内容右下角“×”按钮，选择“不感兴趣”，之后百度将会减少相关推荐。针对所有类型的广告，用户均可通过“设置”'
            + '—“广告屏蔽”开关，智能/手动屏蔽网站广告；也可通过“设置”—“隐私设置”—“程序化广告设置”屏蔽信息流中第三方程序化广告。',
    },
    {
        title: '如何删除搜索历史？',
        text: '在百度 App 首页点击搜索框进入搜索页，搜索框下方的搜索历史记录—旁边的“垃圾箱”按钮，删除历史记录即可。搜索历史是'
            + '本地数据，一旦清理无法恢复。',
    },
    {
        title: '用户访问页面过程中为什么会有安全警告提示？如何消除？',
        text: '百度特设置安全警告以提醒用户访问的网站可能存在安全问题，该警告无法完全消除或关闭。如果用户浏览的网页频繁弹出安全警告，'
            + '用户可将浏览网页的搜索词、截图和页面链接，提交至“我的”—“帮助与反馈”—“产品建议”页面中，百度会尽快处理。',
    },
    {
        title: '如何在“百度知道”设置前台匿名问答或隐藏问答？',
        text: '用户在百度知道 PC 端及 App 端提交问题或答案时，选择勾选编辑器下方“匿名”即可设置前台匿名问答。问答内容将会以匿名或'
            + '者热心网友的形式出现，不会在前台显示用户的 ID 名称。另外，用户可以在百度知道 App 端点击右上角“设置”—“隐私设置”，'
            + '针对“我的提问可见/我的回答可见”进行设置，隐藏问答。',
    }
];