/**
 * @file 隐私管理
 * @author wangzhuang
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import PageBanner from '../../component/PageBanner';
import {controlList} from './controlList';
import './index.scss';

class Control extends React.Component {
    constructor(props) {
        super(props);
        let title = props.match.params.title;
        let num = 0;

        controlList.forEach((item, index) => {
            if (item.title === title) {
                num = index;
            }
        });
        this.state = {
            navIndex: num,
            typeIndex: 0
        };
    }

    render() {
        let {navIndex, typeIndex} = this.state;
        return (
            <div className={'Control'}>
                <PageBanner
                    title={'隐私管理'}
                    titleEn={'Privacy Control'}
                    des={'我们高度重视您对于百度收集使用您的信息的知情权和选择权，同时通过产品中丰富的隐私管理设计，给予您充分的'
                    + '控制权，希望能让您切实感受到“信息被保护，意愿被尊重，服务有价值”。'}
                    imgSrc={require('../../asset/policy/banner.png').default}
                />

                <div className={'con'}>
                    <div className={'top'}>
                        {
                            controlList.map((item, index) => {
                                return <div
                                    className={navIndex === index ? 'item titleF on' : 'item titleF'}
                                    key={index}
                                    onClick={() => {
                                        this.props.history.push(`/control/${item.title}`);
                                        this.setState({
                                            navIndex: index,
                                            typeIndex: 0
                                        });
                                    }}
                                >
                                    {item.title}
                                </div>;
                            })
                        }
                    </div>
                    <div className={'bottom'}>
                        <div className={'left'}>
                            {
                                controlList[navIndex].typeList.map((item, index) => {
                                    return <div
                                        className={typeIndex === index ? 'title on' : 'title'}
                                        key={index}
                                        onClick={() => {
                                            this.setState({
                                                typeIndex: index
                                            });
                                        }}
                                    >
                                        {item.title}
                                    </div>;
                                })
                            }
                        </div>
                        <div className={'right'}>
                            {
                                controlList[navIndex].typeList.map((item, index) => {
                                    if (typeIndex !== index) {
                                        return '';
                                    }
                                    return <div
                                        className={'item'}
                                        key={index}
                                    >
                                        <div className={'img'}>
                                            <img src={item.imgSrc} alt={item.title}/>
                                        </div>
                                        <div className={'des'}>
                                            {item.des}
                                        </div>
                                    </div>;
                                })
                            }

                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default withRouter(Control);