/**
 * @file 隐私政策
 * @author wangzhuang
 */

import React from 'react';
import PageBanner from '../../component/PageBanner';
import {PageList} from './pageList';
import {productList} from '../home/productList';
import './index.scss';

export default class Policy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            scrollIndex: 0
        };
    }

    componentDidMount() {
        this.rightMod.addEventListener('scroll', this.scrollFn);
    }

    componentWillUnmount() {
        this.rightMod.removeEventListener('scroll', this.scrollFn);
    }

    titleRef = (id, off) => ref => {
        this[`title${id}`] = ref;
    };

    scrollFn = () => {
        let sTop = this.rightMod.scrollTop;
        let onIndex = 0;

        PageList.forEach((item, index) => {
            if (sTop >= this[`title${index}`].offsetTop) {
                onIndex = index;
            }
        });

        this.setState({
            scrollIndex: onIndex
        });
    };

    setScrollFn = () => {
        let {scrollIndex} = this.state;
        this.rightMod.scrollTop = this[`title${scrollIndex}`].offsetTop;
    };


    render() {
        let {scrollIndex} = this.state;

        return (
            <div className={'Policy'}>
                <PageBanner
                    title={'隐私政策'}
                    titleEn={'Privacy Policy'}
                    des={'我们通过《百度隐私保护总则》以及各产品单独隐私政策向用户告知百度对于个人信息的处理情况及保护规则。'
                    + '您可通过分栏式的页面设计阅读百度隐私政策，并能够在加粗字体提示下阅读重要条款，更好地了解百度如何收集'
                    + '、使用及保护您的信息。'}
                    imgSrc={require('../../asset/policy/banner.png').default}
                />
                <div className={'section'}>
                    <h3 className={'title titleF'}>
                        百度隐私政策总则
                    </h3>
                </div>

                <div className={'mod2'}>
                    <div className={'left'}>
                        {
                            PageList.map((item, index) => {
                                return <div
                                    className={scrollIndex === index ? 'title link on' : 'title link'}
                                    key={index}
                                    onClick={() => {
                                        this.setState({
                                            scrollIndex: index
                                        }, () => {
                                            this.setScrollFn();
                                        });
                                    }}
                                >
                                    {item.title}
                                </div>;
                            })
                        }
                    </div>
                    <div className={'right'} ref={el => this.rightMod = el}>
                        {
                            PageList.map((item, index) => {
                                /* bca-disable */
                                return <div key={index} ref={this.titleRef(index)}>
                                    {
                                        index === 0
                                            ? <div className={'text'}>
                                                更新日期：2022年4月26日<br/>
                                                生效日期：2022年5月3日<br/>
                                                <b>
                                                    {'欢迎您使用我们的产品和服务！百度公司（本隐私政策中指北京百度网讯科技有限公司，以下亦称“我们”）非常重视您的隐私保护和个人信息保护'
                                                    + '。本隐私政策适用于您通过任何方式对百度公司旗下各项产品和服务的访问和使用。'}
                                                </b>

                                            </div>
                                            : null
                                    }
                                    <div
                                        className={'text'}
                                        dangerouslySetInnerHTML={{__html: item.text}}
                                    >
                                    </div>
                                </div>;
                            })
                        }
                    </div>
                </div>

                <div className={'section'}>
                    <h3 className={'title titleF'}>
                        产品隐私政策
                    </h3>
                    <div className={'apps'}>
                        {
                            productList.map((item, index) => {
                                return <a
                                    className={'item'}
                                    key={index}
                                    href={item.href}
                                    target={'_blank'}
                                >
                                    <div className={'top'}><img src={item.imgSrc} alt={item.title}/></div>
                                    <div className={'title titleF'}>{item.title}</div>
                                </a>;
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }

}