/**
 * @file 落地页banner组件
 * @author wangzhuang
 */

import React from 'react';
import './index.scss';

export default class PageBanner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {title, titleEn, des, imgSrc} = this.props;
        return (
            <div className={'PageBanner'} style={{backgroundImage: `url(${imgSrc})`}}>
                <div className={'pb-con'}>
                    <div className={'pb-title titleF'}>{title}</div>
                    <div className={'pb-title-en titleF'}>{titleEn}</div>
                    <div className={'pb-des'}>{des}</div>
                </div>
            </div>
        );
    }

}
