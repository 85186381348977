/**
 * @file 保护架构
 * @author wangzhuang
 */

import React from 'react';
import PageBanner from '../../component/PageBanner';
import { textList } from './textList.jsx';
import './index.scss';

export default class Organization extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className={'Organization'}>
                <PageBanner
                    title={'保护架构'}
                    titleEn={'Organization'}
                    des={
                        '我们制定了一系列内部管理制度，通过顶层治理架构、安全风险管控体系和安全审计机制，全方位、' +
                        '全流程保障数据安全，保护您的隐私。'
                    }
                    imgSrc={
                        require('../../asset/organization/banner.png').default
                    }
                />

                <div className={'con'}>
                    {textList.map((item, index) => {
                        return (
                            <div className={'item'} key={index}>
                                <h3 className={'titleF'}>
                                    {item.title}
                                    <br />
                                    {item.en}
                                </h3>
                                <p>{item.text}</p>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}
