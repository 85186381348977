/**
 * @file 保护架构数据列表
 * @author wangzhuang
 */
import React from 'react';

export const textList = [
    {
        title: '百度数据管理委员会',
        en: 'Data Management Committee',
    },
    {
        title: '百度于2021年10月成立数据管理委员会（Data Management Committee），原数据资产管理委员会、数据隐私保护委员会、安全委员会职责并入数据管理委员会。',
        en: 'Baidu has established a Data Management Committee in October 2021, which takes the combined responsibilities of the original Data Asset Management Committee, Data Privacy Committee, and Security Committee.',
    },
    {
        title: '百度数据管理委员会是百度公司执行层面专司数据管理和数据安全职责的顶层管理组织，负责决策百度数据管理的重要事项，制定数据管理相关制度、规范、流程、机制，发布数据管理标准，并对日常运营中相关使用行为进行审核。随着数据管理委员会的成立，百度将进一步完善和提升公司在数据管理和数据安全方面的政策、监督和规范实践。',
        en: 'The Data Management Committee of Baidu is a top-level management body dedicated to data management and security at the execution level. It addresses important matters concerning data management, develops data management-related systems, specifications, procedures and mechanisms, issues data management standards, and reviews data use behaviors in daily operations. With the establishment of Data Management Committee, Baidu will further improve polices, supervision and compliance practices in data management and security.',
    },
    {
        title: (
            <>
                点击查看最新
                <a href="/organization/reports" target="_blank">
                    《百度个人信息保护社会责任报告》
                </a>
            </>
        ),
    },
];
export const inforMationTextList = [
    {
        header: '个人信息保护原则',
        textList: [
            '百度建立起覆盖所有相关业务线以及分公司的数据隐私保护制度体系。百度制定了《百度数据安全策略》，以及适用于百度全线产品及服务的《百度隐私政策总则》《百度用户个人信息保护合规总则》《百度公司个人信息保护合规红线》等规范标准，并为旗下产品或服务制定公开、单独的隐私政策，助推隐私保护在各类产品中的落地和应用。',
            '百度遵循“知情同意、最少够用、用户体验、安全保障”四大原则（详见《百度2020年环境、社会及管治（ESG）报告》第40页），保障用户对个人信息的知情权、选择权、控制权（详见《百度2020年环境、社会及管治（ESG）报告》第41页），赋予用户访问、修改和删除个人信息的权利。同时，承诺绝不向任何第三方出售任何个人信息。 ',
        ],
    },
    {
        header: '用户权利的响应',
        noIdentText: '百度赋予用户访问权、修改权、删除权：',
        textList: [
            '访问权：',
            '- 用户有权选择是否提供个人信息，并有权访问其提供的个人信息。',
            '- 百度的平台只有在用户授权或法律法规允许的其他情况下才会收集信息，绝不会强行收集。',
            '- 当基于用户授权而处理个人信息的目的发生变化时，百度会提前通过合理方式提示用户再次授权。',
            '修改权：',
            '- 用户有权修改其个人信息。',
            '- 用户可以在任何时候打开或关闭授权按钮，以授予或撤销他们的同意。',
            '删除权：',
            '- 用户有权提出删除个人信息的请求，可通过个人信息保护问题反馈平台随时向百度反馈需求。',
            '- 百度遵守“最短保存时间”原则，若用户注销账户或主动要求删除用户的个人信息，将在严格按照《网络安全法》等法律法规的信息保存时间的要求前提下，及时对主体个人信息进行删除，并在备份更新时从备份系统中删除这些信息。',
            '- 在用户注销账户或主动要求删除上述信息后，百度承诺不会再对用户的个人信息进行商业化使用，但可能会对用户的个人信息进行匿名化处理后使用。',
            '百度持续完善侵犯个人信息投诉的处理流程，将投诉分为三类：基础类、风险类、函证类，并对投诉处理时限进行明确规定。',
        ],
    },
    {
        header: '隐私技术保障及认证',
        textList: [
            '百度依托在多方安全计算、联邦学习、可信执行环境、安全隔离域、区块链及 AI 安全等领域的一系列技术积累，打造包含数据的采集、识别、存储、使用、传输、共享及销毁的覆盖数据全生命周期各个环节的数据安全与隐私保护解决方案。',
            '百度运用隐私合规检测、差分隐私、AI 自动脱敏、联邦计算、可信计算等技术，有效落实数据安全与隐私保护。此外，针对用户个人敏感信息的处理，百度实现了在系统、存储、服务等各个层面精确到个人的身份认证及访问控制。',
            '为守护云端数据安全，百度新发布了“智能数据安全网关”和“智能威胁狩猎平台”，提供云端一站式敏感信息探测、脱敏、审计等安全管理系统，进一步保障云端系统数据安全。',
            '百度产品和服务已通过多项数据安全及隐私保护国际权威认证。',
        ],
    },
    {
        header: 'AI 核心技术为个人隐私护航',
        textList: [
            '在隐私保护与数据安全层面，百度将一系列访问控制及隐私增强技术应用于数据收集、存储、加工、使用、提供的全生命周期，保护用户隐私的同时释放数据价值。',
            '百度以先进的人工智能技术为支撑，深度贴合国家监管标准，打造“史宾格安全及隐私合规平台”，将隐私基准测试纳入上线前检测流程。作为业界首款对外提供服务的 App 收集使用个人信息合规风险检测和治理系统，“史宾格安全及隐私合规平台”旨在帮助 App 开发和运营企业快速对标国家、行业安全标准与规范，精准识别和检测包括隐私政策文本、App 收集使用个人信息行为、App 用户权利保障等方面的隐私合规风险，实现检测能力与监管要求的一致性，助力自查整改，保障用户隐私安全。目前，百度旗下主要 App和 SDK 均已完成对该平台的接入和内部检测。',
        ],
    },
    {
        header: '百度系产品获得数据安全奖项/荣誉',
        textList: [
            '2021年3月，百度获得由中国信通院颁发的首批“数据安全治理能力评估”优秀级证书。',
            '2021年7月，百度安全史宾格安全及隐私合规平台获得由中国信通院颁发的“2021企业数字化治理先锋实践案例之智能安全与隐私合规类最具价值应用案例”。',
            '2021年7月，百度安全主任架构师包沉浮被聘任为中国人工智能产业发展联盟人工智能治理与可信委员会副主任。',
            '2021年10月，百度安全史宾格、OPENRASP成功入围中国网络安全产业联盟举办的2021年网络安全优秀创新成果大赛。',
            '2021年10月，百度安全安全管理与运营业务线成功入围电子联合会牵头的DCMM优秀案例奖项。',
            '2021年12月，百度安全云上安全产品参与中国信通院“2021年可信云安全案例集材料——可信云云服务用户数据保护”案例入册，并在2021年可信云安全论坛上作为会议材料宣发。',
            '2021年12月，百度当选中国信通院“2021IT 新治理领导力论坛——年度杰出企业”。',
            '2021年12月，百度点石“可信云云服务用户数据保护”案例入选中国信通院“2021年可信云安全案例集材料”，并在2021年可信云安全论坛上作为会议材料宣发。',
            '2021年12月，“隐私计算在生物医疗领域的创新应用案例”入选中国信通院“2021年大数据‘星河’隐私计算标杆案例”。',
            '2021年12月，“百度点石数据安全流通及隐私计算解决方案”入选中国信通院“2021IT新治理领导力论坛——年度卓越创新案例”。',
        ],
    },
    {
        header: '数据安全意识巩固',
        textList: [
            '百度持续加强员工数据安全教育，制订了覆盖全员的培训机制，帮助员工提升数据安全素养及专业技能。',
            '机制保障',
            '针对不同分类分级的数据，百度制订了对应的安全权限管控流程，界定各角色对数据的可见范围及操作规范，以及选择合适的加密、追踪、密钥管理等安全技术。同时，百度与接触用户个人信息的员工、合作伙伴签署保密协议，明确岗位职责及行为准则。',
            '数据安全培训',
            '百度制定了覆盖全员（包括公司管理层、正式员工、生态公司员工、实习生、劳务派遣等外部员工）的安全培训机制。',
            '攻防演练',
            '百度秉承“以攻促防”的理念，定期举办覆盖全员的钓鱼攻防演练。2021年，百度共开展6次钓鱼演练和12次攻防演练。',
            '安全宣传月',
            '2021年，百度开展了以“不动声色的较量”为主题的安全宣传月活动，为全体员工普及安全红线要求、攻防实战及风险事件案例、邮件安全知识等相关内容。',
            '供应商数据安全培训',
            '针对第三方合作，百度会检查其安全资质，考察和评估其安全组织、策略、能力和历史安全问题；要求对方提供数据来源、合规证明、安全证书（如ISO证书）；并与之签订数据安全相关协议。同时，百度为承包商、供应商、外包员工等利益相关方免费提供数据安全及隐私保护相关资源和课程培训服务，帮助生态伙伴提升数据安全防护水平及合规性。',
        ],
    },
    {
        header: '安全管理',
        textList: [
            '百度高度重视网络安全和数据安全。通过建立完善的内部制度体系和安全管理架构，不断提升安全运营的流程管理与技术应用水平。',
            '2021年，百度更新修订了《百度信息及产品安全处罚细则》1《百度安全管理组织规范》2 等规章制度，内容覆盖安全红线、办公安全、基础安全、产品安全、第三方合作安全、安全管理等方面。',
            '百度成立了数据管理委员会（Data Management Committee），作为公司顶层数据管理的决策和执行机构，负责决策公司数据管理重要事项，制定数据管理相关制度、规范、流程、机制，发布数据管理标准，并对日常运营中相关使用行为进行审核。',
            '2021年，百度的隐私态势感知系统、联邦计算等多个产品及解决方案入选国家工业信息安全发展研究中心《人工智能数据安全与监管机制研究》报告。',
        ],
    },
    {
        header: '个人信息保护安全审计',
        textList: [
            '百度依照法律法规要求，针对个人信息数据以及高敏感数据开展基于风险的评估与审计，并由内审部门每半年对执行过程和结果进行审计。此外，百度每年会邀请具有专业资质的第三方机构进行审计认证和安全评估。',
            '2021年，百度参考新实施的《个人信息保护法》，开展了个人信息保护及信息安全专项审计。针对百度系APP引入的第三方SDK，百度制定《第三方SDK安全准入规范》，明确规定第三方SDK在引入前需要从来源、代码和隐私行为三个层面进行安全评估。',
        ],
    },
    {
        header: '「案例」',
        works: [
            {
                secondTitle: '百度开展《个人信息保护法》宣传月活动',
                textList: [
                    '百度内部高度重视个人信息保护， 开展了一系列覆盖全体员工的个人信息保护宣贯工作，旨在全面提升员工的个人信息保护意识。',
                    '为响应国家新出台的《个人信息保护法》，百度举办了“AI 这星辰大海， 共筑个人信息安全港”专题宣传月活动，并在百度安全响应中心的公众号上全面解读企业如何贯彻落实个人信息保护。宣传月活动旨在倡导开启一种新的善用用户个人信息的合规文化。',
                    '此外，由CCIA数据安全工作委员会主办，百度安全、安恒信息、OASES联合承办的“个人信息安全有法可依”专家在线对话主题直播活动于2021年10月15日举行，旨在响应国家网络安全宣传周活动，推动法律法规实施，传递网络安全知识，体现社会责任。直播活动围绕《个人信息保护法》，从个人信息的范围界定、过度收集行为的判定、企业的核心义务、个人权利的实现、法律法规施行的影响、个人维权的方法等角度，对社会高度关注的一系列问题进行深入探讨，并给出建议和方案。',
                ],
            },
            {
                secondTitle:
                    '百度安全发布PaddleSleeve面向产业实践开启AI模型安全与隐私探索',
                textList: [
                    '百度坚守个人隐私保护红线，积极研发隐私保护增强技术，不断探索覆盖数据全生命周期的数据安全与隐私保护解决方案。',
                    '2021 年 9 月 26 日，百度安全正式对外发布了基于百度“飞桨”开源深度学习平台的安全与隐私工具PaddleSleeve。依托百度“飞桨”，百度安全的PaddleSleeve旨在提供一个不止于学术研究、更适用于产业实践的安全工具。PaddleSleeve以场景为驱动，覆盖现实风险，支持产业级模型，为有高安全性、高隐私性需求的场景提供端到端的安全技术支撑，并全面覆盖AI模型所需应对的从Security到Safety再到Privacy的各个层面的安全挑战。',
                    '具体而言，PaddleSleeve 可为企业和开发者带来两个层面的实用功能：',
                    '在模型攻击与评估层面，PaddleSleeve融合了百度安全Advbox对抗样本攻击及Robustness 鲁棒性评估等诸多能力，并新增了对“飞桨”2.X 版本的支持，引入了更多前沿攻击算法和模型集成迁移攻击策略。同时，聚焦于隐私性，PaddleSleeve支持7类隐私攻击效果评估指标，由此实现对模型数据被还原及模型关键信息泄露风险的检测。',
                    '在模型防御层面，PaddleSleeve支持多个业界前沿、性能良好的对抗训练方法，企业和开发者可通过新训练、模型精调等方式进行对抗训练，并可借助多种过滤算法在不修改模型的情况下实现非侵入式的对抗鲁棒性增强。而基于差分隐私扰动、梯度压缩等方法的隐私增强优化器，其也可帮助企业和开发者更为便捷地训练出可有效抵御常见隐私窃取攻击的模型。',
                    '目前，PaddleSleeve已在多个场景中实现对各类通用产业级模型、预训练模型及“飞桨”自定义模型的支持。而伴随一系列新功能的不断加入，百度安全也希望与学术界、产业界持续展开合作，以场景为驱动，面向产业实践，为AI模型安全与隐私保护提供更好的能力支撑。',
                ],
            },
            {
                secondTitle: '百度隐私计算点石联邦学习平台通过测评',
                textList: [
                    '百度高度重视数据安全，不断创新研发隐私保护技术和产品，并获得了广泛认可标准的认证。',
                    '百度点石联邦学习平台基于MPC（Multi-party Computation）、FL（Federated Learning）、DP（Differential Privacy）等技术，有效保障参与方数据安全。在数据不出本地的情况下和多个参与方之间通过共享加密数据的参数交换与优化，进行机器学习，建立虚拟共享模型，从而实现数据的多方协同和授权共享，得到更准确、更高效的模型和决策，进一步释放数据价值。百度点石联邦学习平台将政府数据赋能金融、汽车、教育、互联网等行业客户，解决风险控制、精准营销等场景的业务需求，打破数据孤岛，实现数据价值的充分流动。',
                    '百度点石联邦学习平台在数据安全方面拥有技术和产品的双重保障，成为国内首家同时通过“基于多方安全计算的数据流通产品技术要求和测试方法 ”“可信数据流通平台”两个测试的大数据平台，以及首家通过中国金融认证中心（CFCA）多方安全计算测评的大数据平台。百度点石联邦学习平台满足JR/T 0196-2020《多方安全计算金融应用技术规范》和T/PCAC 0009-2021《多方安全计算金融应用评估规范》的要求， 使多方安全计算技术服务金融场景， 使数据共享和数据合作更加标准化 和规范化。',
                    '此外，中国信通院以BDC 41-2020《基于联邦学习的数据流通产品—— 技术要求与测试方法》为考核依据， 从调度管理能力、数据处理能力、算法实现、效果及性能和安全性、稳定性、可拓展性五大测评维度对百度点石联邦学习平台开展了详细的考核。最终，百度点石联邦学习平台全项通过“联邦学习——基础能力专项测评”。',
                    '未来，百度点石联邦学习平台将继续坚持自主研发，并积极与产、学、研各界紧密合作，不断完善自身产品及服务，推动我国大数据、隐私计算产品技术水平不断提升，为数据强国提供强有力的技术支撑。',
                ],
            },
            {
                secondTitle:
                    '百度人脸活体检测系统（V2.0）通过中国信通院“护脸计划”首批优秀级安全防护能力评估',
                textList: [
                    '百度始终坚持在业务运营过程中保护个人隐私的理念，不断加大产品全生命周期的数据安全保护力度， 竭力保护用户隐私。同时，百度的创新安全产品也获得了信息安全方面广泛认可标准的认证。',
                    '2021 年，百度人脸活体检测系统（V2.0）以全项通过的优异成绩， 顺利通过中国信通院“可信人脸应用守护计划”（简称护脸计划）测试，获评优秀级（四级）安全防护等级，成为首批通过可信人脸评估的系统，展现了百度人脸活体检测系统（V2.0）在安全可靠、透明性、数据保护、明确责任、公平性等方面的可信能力，及在活体防攻击能力及安全性方面的行业领先性。',
                    '近年来，人脸识别技术被广泛应用于公共安全、金融支付、交通出行等领域。然而，在使用过程中，该项技术也暴露出可能侵犯隐私、出现安全风险等问题。百度人脸安全方案是以人脸活体检测技术为基础， 依托百度安全强大的深度学习人工智能前沿技术框架，结合与黑产多年的对抗经验，自主研发的新一代安全SDK技术，打造的一套“高安全性”“高通过率”“高识别率” 的人脸实名认证解决方案。方案摒弃了传统人脸SDK直接采集摄像头数据的方式，升级为安全数据通道采集，从代码逻辑、采集方式、传输层进行多维度加固。同时，方案真正实现了对采集的图像/ 视频等内容的数据白盒加密处理，在云端对数据解密分析，避免数据中途被篡改，保障数据传输的安全性，确保人脸数据采集真实有效。在采集高安全性的基础上，人脸实名认证方案还提供多达10种可选的活体检测方法，包括图片、视频、语音、动作以及创新的炫瞳活体等，有效提高 APP、H5及小程序场景下人脸实名认证的可靠性和便捷性。',
                    '未来，百度将继续完善人脸安全技术方案，确保人脸识别在不同生命周期的安全性。同时，百度还将积极协同生态内各方力量，通过标准制定、测试评估、行业自律、威胁共治等手段，有效回应用户的信任问题，增强行业和社会对人脸识别安全问题的认识，促进生物识别产业的健康、有序发展。',
                ],
            },
        ],
    },
];
