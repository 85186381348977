/**
 * @file 导航
 * @author wangzhuang
 */

import React from 'react';
import {withRouter} from 'react-router-dom';
import './index.scss';

const navList = [
    {
        title: '首页',
        titleEn: 'Home',
        url: '/'
    },
    {
        title: '隐私政策',
        titleEn: 'Privacy Policy',
        url: '/policy'
    },
    {
        title: '隐私管理',
        titleEn: 'Privacy Control',
        url: '/control'
    },
    {
        title: '保护架构',
        titleEn: 'Organization',
        url: '/organization'
    },
    {
        title: '常见问题',
        titleEn: 'FAQ',
        url: '/faq'
    }
];

class Nav extends React.Component {

    pushUrl = url => {
        this.props.history.push(url);
    };

    render() {
        return (
            <div className={'nav'}>
                <div className={'logo'}>
                    <div
                        onClick={() => {
                            this.pushUrl('/');
                        }}
                    >
                        <img src={require('../../asset/home/logo.png').default} alt=""/>
                    </div>
                    <div className={'name'}>Baidu Privacy Protection</div>
                </div>
                <div className={'menu'}>
                    {
                        navList.map((item, index) => {
                            let className = 'link';
                            let pathName = this.props.location.pathname;

                            if (index === 0) {
                                if (pathName === item.url) {
                                    className = 'link on';
                                }
                            } else if (pathName.indexOf(item.url) !== -1) {
                                className = 'link on';
                            }
                            return <div
                                className={'item'}
                                key={index}>
                                <div
                                    className={className}
                                    onClick={() => {
                                        this.pushUrl(item.url);
                                    }}
                                >
                                    {item.title}
                                </div>
                                <div className={'en'}>{item.titleEn}</div>
                            </div>;
                        })
                    }
                </div>
            </div>
        );
    }
}

export default withRouter(Nav);
