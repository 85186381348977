/**
 * @file 标题模块
 * @author wangzhuang
 */

import React from 'react';
import './index.scss';

export default class TitileBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {title, text} = this.props;
        return (<div className={'titleBox'}>
            <div className={'title titleF'}>{title}</div>
            <div className={'text'}>{text}</div>
        </div>);
    }
}
