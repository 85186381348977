/**
 * @file 图片标题文本组件
 * @author wangzhuang
 */

import React from 'react';
import './index.scss';

export default class ImgText extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {imgSrc, title, text} = this.props;
        return (
            <div className={'imgText'}>
                <img src={imgSrc} alt={title}/>
                <div className={'title'}>{title}</div>
                <div className={'text'}>{text}</div>
            </div>
        );
    }
}
